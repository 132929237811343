<template>
  <div class="selectCdList">
    <el-input
      v-model="searchText"
      :placeholder="$t('home.commonSeacrh')"
      class="selectCdList_input"
      v-if="isSearch"
    />

    <ul>
      <li
        v-for="(item, index) in pageDataList"
        :key="'selectCdItem' + index"
        :class="{ selected: item.selected }"
        @click="selectFn(index)"
        v-show="
          $i18n.locale === 'cn'
            ? item.cName
              ? item.cName.toLowerCase().indexOf(searchText.toLowerCase()) != -1
              : item.eName.toLowerCase().indexOf(searchText.toLowerCase()) != -1
            : item.eName
            ? item.eName.toLowerCase().indexOf(searchText.toLowerCase()) != -1
            : item.cName.toLowerCase().indexOf(searchText.toLowerCase()) != -1
        "
      >
        {{
          $i18n.locale === "cn"
            ? item.cName || item.eName
            : item.eName || item.cName
        }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: [
    "dataList",
    "dataValue",
    "dataType",
    "indexInfo",
    "dataKey",
    "dataNameKey",
    "isSearch",
  ],
  data() {
    return {
      pageDataValue: "",
      pageDataList: [],
      subParams: {
        data: "",
        dataName: "",
        dataKey: "",
        dataNameKey: "",
        dataType: "",
        indexInfo: "",
      },

      searchText: "",
    };
  },
  mounted() {
    this.pageDataValue = this.dataValue
      ? this.dataValue
      : this.dataType && this.dataType === "array"
      ? []
      : "";
    this.pageDataList = JSON.parse(JSON.stringify(this.dataList));

    this.pageInit();
  },

  watch: {
    dataValue(newVal) {
      this.pageDataValue = this.dataValue
        ? this.dataValue
        : this.dataType && this.dataType === "array"
        ? []
        : "";
      this.pageDataList = JSON.parse(JSON.stringify(this.dataList));

      this.pageInit();
    },
  },

  methods: {
    pageInit() {
      this.subParams.dataKey = this.dataKey;
      this.subParams.dataNameKey = this.dataNameKey;
      this.subParams.dataType = this.dataType;
      this.subParams.indexInfo = this.indexInfo;

      if (this.dataType && this.dataType === "array") {
        if (this.pageDataValue) {
          this.subParams.data = [];
          this.subParams.dataName = [];

          this.pageDataList.forEach((val) => {
            this.$set(val, "selected", false);
          });

          this.pageDataList.forEach((val) => {
            this.pageDataValue.forEach((val2) => {
              if (val.value === val2) {
                this.$set(val, "selected", true);
              }
            });
          });
        }
      } else {
        this.pageDataList.forEach((val) => {
          this.$set(val, "selected", false);
        });

        this.pageDataList.forEach((val) => {
          if (val.value == this.pageDataValue) {
            this.$set(val, "selected", true);
          }
        });
      }
    },
    selectFn(index) {
      if (this.dataType && this.dataType === "array") {
        this.$set(
          this.pageDataList[index],
          "selected",
          this.pageDataList[index].selected ? false : true
        );

        this.pageDataList.forEach((val, e) => {
          if (val.selected) {
            this.subParams.data.push(val.value);
            this.subParams.dataName.push(
              this.$i18n.locale === "cn" ? val.cName : val.eName
            );
          } else {
            this.subParams.data.some((val2, e2) => {
              if (val.value === val2) {
                this.subParams.data.splice(e2, 1);
                this.subParams.dataName.splice(e2, 1);
              }
            });
          }
        });

        this.subParams.data = [...new Set(this.subParams.data)];
        this.subParams.dataName = [...new Set(this.subParams.dataName)];

        this.$emit("select", this.subParams);
      } else {
        this.pageDataList.forEach((val, e) => {
          if (e == index) {
            this.subParams.data = val.value;
            this.subParams.dataName =
              this.$i18n.locale === "cn" ? val.cName : val.eName;

            this.$set(val, "selected", true);
            this.$emit("select", this.subParams);
          } else {
            this.$set(val, "selected", false);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.selectCdList {
  width: 100%;

  .selectCdList_input {
    padding: toPad(15) toPad(15) 0 toPad(15);

    .el-input__inner {
      height: toPad(38);
      line-height: toPad(38);
      border: 0;
      background: #f4f4f4;
      border-radius: 3px;
      font-size: toPad(14);
    }
  }
  ul {
    display: block;
    width: 100%;
    padding: 0 toPad(15);
    margin: toPad(10) 0;
    max-height: toPad(190);
    overflow: auto;

    li {
      width: 100%;
      padding: toPad(7) toPad(10);
      margin: toPad(5) 0;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.selected {
        background-color: #d8e7e2;
      }
    }
  }
}
</style>
