<template>
  <div class="selectCdTree">
    <el-input :placeholder="$t('home.commonSeacrh')" v-model="filterText">
    </el-input>

    <div class="selectCdTree-content">
      <el-tree
        class="filter-tree"
        :data="data"
        :props="defaultProps"
        :default-expand-all="false"
        :filter-node-method="filterNode"
        @node-click="nodeClick"
        ref="tree"
      >
      </el-tree>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  props: ["dataList", "indexInfo", "dataKey", "dataNameKey"],
  name: "selectCdTree",
  data() {
    return {
      filterText: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  methods: {
    ...mapActions({
      fieldValueOptionUri: "crowd/fieldValueOptionUri",
    }),

    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    pageInit(data) {
      data.forEach((val) => {
        this.$set(
          val,
          "label",
          this.utils.formatLang(this.$i18n.locale, val.catCnName, val.catEnName)
        );

        if (val.queryFieldDtos && val.queryFieldDtos.length > 0) {
          val.children = [];
          val.children = val.queryFieldDtos;

          val.children.forEach((valquery, equery) => {
            this.$set(valquery, "condStatus", true);

            this.$set(valquery, "catCnName", valquery.fieldCommentCnName);

            this.$set(valquery, "catEnName", valquery.fieldCommentEnName);
          });
        }

        if (val.children && val.children.length > 0) {
          this.pageInit(val.children);
        }
      });
    },
    async nodeClick(val, node, thisRef) {
      let dataLs = val.operateSymbol || [];

      dataLs.forEach((val) => {
        val.cName = val.labelCnName;
        val.eName = val.labelEnName;
      });

      if (val.condStatus) {
        if (val.fieldValueType === "remote") {
          let dataParams = {
            url:
              process.env.NODE_ENV === "development"
                ? "api" + val.fieldValueOptionUri
                : val.fieldValueOptionUri,

            params: {
              fieldId: val.id,
            },
          };

          let res = await this.fieldValueOptionUri(dataParams);

          let dataLs3 = res.data.data || [];

          dataLs3.forEach((val) => {
            val.cName = val.labelCnName;
            val.eName = val.labelEnName;
          });

          let params = {
            fieldId: val.id,
            data: val.id,
            dataName: val.label,
            dataKey: this.dataKey,
            dataNameKey: this.dataNameKey,
            indexInfo: this.indexInfo,
            cond2queryData: dataLs,
            cond3queryData: dataLs3,
            fieldType:
              val.fieldType != "number"
                ? val.fieldType
                : dataLs3 && dataLs3.length > 0
                ? "arraySingle"
                : "number",
          };

          this.$emit("change", params);
        } else {
          let dataLs3 = val.fieldValueOption || [];

          dataLs3.forEach((val) => {
            val.cName = val.labelCnName;
            val.eName = val.labelEnName;
          });

          let params = {
            fieldId: val.id,
            data: val.id,
            dataName: val.label,
            dataKey: this.dataKey,
            dataNameKey: this.dataNameKey,
            indexInfo: this.indexInfo,
            cond2queryData: dataLs,
            cond3queryData: dataLs3,
            fieldType:
              val.fieldType != "number"
                ? val.fieldType
                : dataLs3 && dataLs3.length > 0
                ? "arraySingle"
                : "number",
          };

          if (val.fieldType) {
            this.$emit("change", params);
          }
        }
      }
    },
  },

  mounted() {
    this.data = this.dataList;

    this.pageInit(this.data);

    this.$forceUpdate();
  },
};
</script>

<style lang="scss" scoped>
@import "@/src/styles/variable.scss";

.selectCdTree {
  width: 100%;
  padding: toPad(15);

  .el-tree {
    color: #000;
  }

  .el-input {
    .el-input__inner {
      height: toPad(38);
      line-height: toPad(38);
      border: 0;
      background: #f4f4f4;
      border-radius: 3px;
      font-size: toPad(14);
    }
  }
  .selectCdTree-content {
    width: 100%;
    max-height: toPad(190);
    overflow: auto;
    margin-top: toPad(10);
  }

  .el-tree-node {
    white-space: normal;
    margin: toPad(10) 0 0 0;
  }

  .el-tree-node:focus > .el-tree-node__content {
    background-color: #d8e7e2;
  }

  .el-tree-node__content:hover {
    background-color: transparent;
  }
  .el-tree-node__content {
    height: auto;
    padding-top: toPad(4);
    padding-bottom: toPad(4);
  }
  .el-tree-node__expand-icon {
    color: #000;
  }
  .el-tree-node__expand-icon.is-leaf {
    color: transparent;
  }
}
</style>
